import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class Remarks extends Vue {

  @Prop({ default: '' })
  private value!: string

  @Prop({ default: false })
  private disabled!: boolean

  private remarks = this.value

  @Watch('value')
  private valueChanged(val: string, oldVal: string) {
    if (val !== oldVal) {
      this.remarks = val
    }
  }

  @Watch('remarks')
  private remarksChanged(val: string, oldVal: string) {
    if (val !== oldVal) {
      this.$emit('input', val)
    }
  }
}
