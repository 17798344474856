import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class OrigDst extends Vue {

  @Prop({ default: '' })
  private origin!: string

  @Prop({ default: '' })
  private destination!: string

  @Prop({ default: false })
  private disabled!: boolean

  private pOrigin = this.origin
  private pDestination = this.destination

  @Watch('origin')
  private originChanged(val: string, oldVal: string) {
    if (val !== oldVal) {
      this.pOrigin = val
    }
  }

  @Watch('pOrigin')
  private pOriginChanged(val: string, oldVal: string) {
    if (val !== oldVal) {
      this.$emit('update:origin', val)
    }
  }

  @Watch('destination')
  private destinationChanged(val: string, oldVal: string) {
    if (val !== oldVal) {
      this.pDestination = val
    }
  }

  @Watch('pDestination')
  private pDestinationChanged(val: string, oldVal: string) {
    if (val !== oldVal) {
      this.$emit('update:destination', val)
    }
  }
}
