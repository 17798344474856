import PaginationResponse from '@/common/components/pagination/pagination-response'
import { AxiosResponse } from 'axios'

export default class Responses {

  public static pagedList<T>(res: AxiosResponse<PaginationResponse<T>>) {
    if (res && res.data) {
      return res.data
    }
    return new PaginationResponse<T>()
  }

  public static list<T>(res: AxiosResponse<T[]>) {
    if (res && res.data) {
      return res.data
    }
    return []
  }

  public static obj<T>(res: AxiosResponse<T>) {
    if (res && res.data) {
      return res.data
    }
    return null
  }
}
