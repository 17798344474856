









import moment, { Moment } from 'moment'
import {
  Component,
  Mixins,
  Prop,
  Ref,
  Vue,
  Watch,
} from 'vue-property-decorator'

@Component
export default class XMoneyInput extends Vue {
  @Prop({ type: Number })
  private value!: number | null

  @Prop({ type: String, default: '€' })
  private currency!: string

  @Prop({ type: Number, default: 2 })
  private fractionalDigits!: number

  private amount = this.value

  @Watch('value')
  private valueChanged(val: number, old: number) {
    if (val !== old) {
      this.amount = val
    }
  }

  @Watch('amount')
  private amountChanged(val: number, old: number) {
    if (val !== old) {
      this.$emit('input', val)
    }
  }
}
