import modules from '@/app/config/modules'
import assign from 'lodash.assign'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {},

  mutations: {
    init(state) {
      const persisted = localStorage.getItem('taxi-office-state')
      if (persisted) {
        assign(state, JSON.parse(persisted))
      }
    },

    // purge (state) {
    //   localStorage.removeItem('taxi-office-state')
    // }
  },

  actions: {},

  modules,
})

store.subscribe((_, state) => {
  localStorage.setItem('taxi-office-state', JSON.stringify(state))
})

export default store
