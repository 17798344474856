import PagedMixin from "@/app/mixins/paged-mixin"
import PaginationRequest from "@/common/components/pagination/pagination-request"
import Customer from "@/customers/entities/customer"
import customersService from "@/customers/services/customers-service"
import { Component, Mixins, Prop, Vue, Watch } from "vue-property-decorator"

@Component
export default class CustomerSelect extends Mixins(PagedMixin) {
  @Prop({ default: null })
  private value!: Customer | null

  @Prop({ default: false })
  private disabled!: boolean

  private loading: boolean = false
  private customer: Customer | null = this.value
  private customers: Customer[] = this.value ? [this.value] : []
  private search: string = ""
  private searchDialog: string = ""
  private newCustomer = new Customer()
  private dialog = false
  private customerHeaders = [
    { text: "Nachname", value: "lastName" },
    { text: "Vorname", value: "firstName", sortable: false },
    { text: "Firma", value: "company", sortable: false },
    { text: "Telefon", value: "phone", sortable: false },
  ]

  @Watch("value", { deep: true })
  private watchValue(val: Customer, oldVal: Customer) {
    if (val === null) {
      this.customer = null
      this.customers = []
    } else {
      this.customer = val
      this.customers = [val]
    }
  }

  @Watch("customer", { deep: true })
  private watchCustomer(val: Customer, oldVal: Customer) {
    if (val !== oldVal) {
      this.$emit("input", val)
    }
  }

  @Watch("search")
  private async watchSearch(val: string) {
    if (val && val.length > 2) {
      this.loading = true
      this.customers = await customersService.searchAsync(val)
      this.loading = false
    }
  }

  @Watch("pPagination", { deep: true })
  private async paginationChangedAsync(
    newVal: PaginationRequest,
    oldVal: PaginationRequest
  ) {
    if (newVal !== oldVal) {
      await this.fetchCustomersAsync()
    }
  }

  private get canAddCustomer() {
    return this.newCustomer.hasNameOrCompany
  }

  private async pickCustomerAsync() {
    this.dialog = true
    this.searchDialog = this.search
    await this.fetchCustomersAsync()
  }

  private async fetchCustomersAsync() {
    this.loading = true
    const response = await customersService.filterAllAsync(
      this.searchDialog,
      this.pPagination
    )
    this.customers = response.items
    this.pPagination.totalItems = response.totalItems
    this.loading = false
  }

  private customerClicked(customer: Customer) {
    this.customer = customer
    this.dialog = false
  }

  private async addCustomerAsync() {
    this.loading = true
    let createdCustomer = await customersService.createAsync(this.newCustomer)
    await this.fetchCustomersAsync()
    this.newCustomer.firstName = ""
    this.newCustomer.lastName = ""
    this.newCustomer.company = ""
    this.newCustomer.phone = ""
    this.loading = false

    if (createdCustomer) {
      this.customerClicked(createdCustomer)
    }
  }

  private separated(parts: string[], separator: string) {
    return parts.filter((p) => p).join(` ${separator} `)
  }

  private select(customer: Customer) {
    this.customer = customer
  }

  private mounted() {
    this.pPagination.sortBy.push("lastName")
  }
}
