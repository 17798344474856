import Customer from '@/customers/entities/customer'
import cloneDeep from 'lodash/cloneDeep'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CustomerCategory from '@/customers/entities/customer-category'
import customersService from '@/customers/services/customers-service'

@Component
export default class CustomerDialog extends Vue {

  private dialog: boolean = false
  private editMode: boolean = false
  private customer: Customer = new Customer()
  private categories: CustomerCategory[] = []

  public create(customer?: Customer) {
    this.customer = customer || new Customer()
    this.dialog = true
    this.editMode = false
    this.$validator.reset()
  }

  public edit(customer: Customer) {
    this.customer = cloneDeep(customer)
    this.dialog = true
    this.editMode = true
    this.$validator.reset()
  }

  public async save() {
    if (await this.$validator.validateAll()) {
      this.$emit(this.editMode ? 'update' : 'save', this.customer)
      this.dialog = false
    }
  }

  public cancel() {
    this.$emit('cancel')
    this.dialog = false
  }

  private async mounted() {
    this.categories = await customersService.findAllCategoriesAsync()
  }
}
