import Dates from '@/app/utils/dates'
import moment, { Moment } from 'moment'
import Vue from 'vue'

Vue.filter('moment', (v: string) => {
  return moment(v)
})

Vue.filter('weekday', (v: string | number | void | Moment) => {
  if (v) {
    return Dates.getWeekName(v)
  }
  return ''
})

Vue.filter('day', (v: string | number | void | Moment) => {
  if (v) {
    return Dates.day(v)
  }
  return ''
})

Vue.filter('date', (v: string | number | void | Moment) => {
  if (v) {
    return Dates.date(v)
  }
  return ''
})

Vue.filter('dateTime', (v: string | number | void | Moment) => {
  if (v) {
    return Dates.dateTime(v)
  }
  return ''
})

Vue.filter('time', (v: string | number | void | Moment, seconds: boolean = false) => {
  if (v) {
    return Dates.time(v, seconds)
  }
  return ''
})

Vue.filter('calendar', (v: string | number | void | Moment) => {
  if (v) {
    return moment(v).locale('de').calendar()
  }
  return ''
})
