import { Component, Prop, Vue } from "vue-property-decorator"
import Customer from "@/customers/entities/customer"

@Component
export default class CustomerName extends Vue {
  @Prop({ default: null })
  private value!: Customer | null

  getFullName() {
    if (this.value) {
      return Customer.getFullName(this.value)
    } else {
      return "-"
    }
  }
}
