import moment, { duration, Moment, unitOfTime, MomentInput } from 'moment'

export default class Dates {

  public static readonly weeks = [
    'Mo',
    'Di',
    'Mi',
    'Do',
    'Fr',
    'Sa',
    'So',
  ]

  public static readonly months = [
    'Jan',
    'Feb',
    'Mär',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dez',
  ]

  public static getWeekName(v: MomentInput) {
    const id = moment(v).isoWeekday()
    return 1 <= id && id <= 7 ? this.weeks[id - 1] : ''
  }

  public static getMonthName(id: number) {
    return 1 <= id && id <= 12 ? this.months[id - 1] : ''
  }

  public static day(v: MomentInput) {
    return moment(v).format('DD')
  }

  public static date(v: MomentInput) {
    return moment(v).format('DD.MM.YYYY')
  }

  public static dateTime(v: MomentInput) {
    return moment(v).format('DD.MM.YYYY HH:mm')
  }

  public static time(v: MomentInput, seconds: boolean = false) {
    return moment(v).format(seconds ? 'HH:mm:ss' : 'HH:mm')
  }

  public static combine(date: string, time: string) {
    return moment(`${date} ${time}`).format('YYYY-MM-DD HH:mm')
  }

  public static combineToISO(date: string, time: string) {
    return moment(`${date} ${time}`).toISOString(true)
  }

  public static combineDateAndTime(date: moment.Moment, time: moment.Moment) {
    const res = date.clone()
    res.hour(time.hour())
    res.minute(time.minute())
    res.second(time.second())
    res.millisecond(time.millisecond())
    return res
  }

  public static minutesAsDuration(dur: number) {
    let start = moment()
    let end = moment().add(dur, 'minutes')
    return moment.utc(end.diff(start)).format('HH:mm:ss')
  }

  public static periodText(start: moment.Moment, end: moment.Moment, unit: unitOfTime.Base) {

    const absDiff = Math.abs(end.diff(start, unit))
    const step = Math.max(absDiff, 1)

    const dayStart = moment().startOf('day')
    const dayEnd = moment().endOf('day')
    const limit = dayEnd.diff(dayStart, unit) + 1

    if (start.isSame(end, 'day')) {
      if (step <= limit) {
        const date = start.format('DD.MM')
        const startTime = start.format('HH:mm')
        const endTime = end.format('HH:mm')
        return `${date} ${startTime} - ${endTime}`
      } else {
        const startDay = start.format('DD.')
        const endDate = end.format('DD.MM')
        return `${startDay} - ${endDate}`
      }
    } else {
      if (step <= limit) {
        const startDate = start.format('DD.MM HH:mm')
        const endDate = end.format('DD.MM HH:mm')
        return `${startDate} - ${endDate}`
      } else {
        const startDate = start.format('DD.MM')
        const endDate = end.format('DD.MM')
        return `${startDate} - ${endDate}`
      }
    }
  }
}
