import Dates from '@/app/utils/dates'
import { RidePaymentMethod } from '@/rides/models/ride-payment-method'
import moment, { Moment } from 'moment'
import Vue from 'vue'

Vue.filter('ridePaymentMethod', (v: RidePaymentMethod) => {
  switch (v) {
    case RidePaymentMethod.Cash: return 'Bar'
    case RidePaymentMethod.Invoice: return 'Rechnung'
  }
  return ''
})
