import Vue from 'vue'
import { PluginObject, VueConstructor } from 'vue'

import numeral from 'numeral'

declare module 'vue/types/vue' {
  interface Vue {
    $fmt: Formatter
  }
}

class FormatterOptions {
  integers = {
    input: '0',
    display: '0,0',
  }
}

class Formatter {
  private opts: FormatterOptions = new FormatterOptions()

  constructor(opts?: Partial<FormatterOptions>) {
    Object.assign(this.opts, opts)
  }

  public num(val: number | null, fractionalDigits: number = 0): string {
    if (val == null) {
      return ''
    }
    const format = this.opts.integers.display
    const fraction = '0'.repeat(fractionalDigits)
    return numeral(val).format(`${format}.${fraction}`)
  }

  public inputNum(val: number | null, fractionalDigits: number = 0): string {
    if (val == null) {
      return ''
    }
    const format = this.opts.integers.input
    const fraction = '0'.repeat(fractionalDigits)
    return numeral(val).format(`${format}.${fraction}`)
  }

  public parseNum(val: any): number | null {
    if (val == null) {
      return null
    }
    return numeral(val).value()
  }
}

class VueFormatterPlugin implements PluginObject<any> {
  public install(vue: VueConstructor, options?: any): void {
    vue.prototype.$fmt = new Formatter()
  }
}

Vue.use(new VueFormatterPlugin())
