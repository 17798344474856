import Guid from '@/app/utils/guid'

export default class Customer {

  public id: string = Guid.empty()
  public tenantId: string = Guid.empty()
  public firstName: string = ''
  public lastName: string = ''
  public company: string = ''
  public vat: string = ''
  public email: string = ''
  public phone: string = ''
  public country: any | null = null
  public countryId: string = 'AT'
  public city: string = ''
  public addressLine: string = ''
  public remarks: string = ''

  public get fullName() {
    return Customer.getFullName(this)
  }

  public static getFullName(c: Customer) {
    if (c.firstName && c.lastName) {
      return c.firstName + ' ' + c.lastName
    } else if (!c.firstName && c.lastName) {
      return c.lastName
    } else if (c.firstName && !c.lastName) {
      return c.firstName
    } else if (c.company) {
      return c.company
    } else {
      return "(kein Name)"
    }
  }

  public get isNew() {
    return this.id == Guid.empty()
  }

  public get hasName() {
    return this.firstName || this.lastName
  }

  public get hasNameOrCompany() {
    return this.firstName ||this.lastName || this.company
  }
}
