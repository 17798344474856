export default class PaginationRequest {

  public page: number = 1
  public itemsPerPage: number = 10
  public sortBy: string[] = []
  public sortDesc: boolean[] = []
  public totalItems: number = 0

  public groupBy: string[] = []
  public groupDesc: boolean[] = []

  public multiSort: boolean = false
  public mustSort: boolean = false

  constructor(itemsPerPage: number = 10) {
    this.itemsPerPage = itemsPerPage
  }

  get length() {
    return Math.ceil(this.totalItems / this.itemsPerPage)
  }

  validatePage() {
    if ((this.page - 1) * this.itemsPerPage > this.totalItems) {
      this.page = 1
    }
  }
}
