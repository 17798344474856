import store from '@/app/store'
import ItinerariesModule from '@/itineraries/store/itineraries-module'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component
export default class App extends Vue {

  private snacks: boolean = false
  private dialog: boolean = false
  private drawer: boolean = false
  private color: string = 'info'
  private msg: string = ''

  private itinerariesModule = getModule(ItinerariesModule, store)

  get showHeader() {
    const show = this.$route.meta.showHeader
    if (show === undefined) {
      return true
    }
    return show
  }

  get enabledSideMenu() {
    return this.$route.meta.showSideMenu
  }

  get fullscreen() {
    return this.$route.meta.fullscreen || false
  }

  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(newVal: any) {
    this.drawer = this.enabledSideMenu
      && this.$vuetify.breakpoint.lgAndUp
      && newVal.name !== 'login'
  }

  private moveToTop() {
    this.$vuetify.goTo('#header')
  }

  private created() {
    this.$bus.$on('app.snack.show', this.showSnacks)
    this.$bus.$on('app.exception-dialog.show', this.showExceptionDialog)
    this.$bus.$on('auth.logged-in', this.prefetch)
  }

  private async prefetch() {
    if (this.$auth.isAdmin === false) {
      await this.itinerariesModule.fetchAll()
    }
  }

  private showSnacks(color: string, msg: string) {
    this.color = color
    this.msg = msg
    this.snacks = true
  }

  private showExceptionDialog(msg: string) {
    this.msg = msg
    this.dialog = true
  }
}
