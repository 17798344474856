import Customer from '@/customers/entities/customer'
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class CustomerCard extends Vue {

  @Prop({ default: () => new Customer() })
  private customer!: Customer

  public edit() {
    this.$emit('edit', this.customer)
  }

  public remove() {
    this.$emit('remove', this.customer)
  }
}
