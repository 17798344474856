import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import de from 'vuetify/src/locale/de'
import vueDebounce from 'vue-debounce'

import '@/app/assets/icon-font/iconfont.css'

Vue.use(Vuetify)
Vue.use(vueDebounce)

const ICONS = {
  fahrten: 'icon-menu-fahrten',
}

const opts = {
  icons: {
    iconfont: 'md', // || 'mdiSvg' || 'md' || 'fa' || 'fa4'
    // values: ICONS,
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#FDBA12',
        secondary: '#BD8B0D',
        accent: '#25282D',
        error: '#E12929',
        warning: '#997733',
        info: '#337799',
        success: '#1A9115',
      },
      dark: {
        primary: '#555',
      },
    },
  },
  lang: {
    locales: { de },
    current: 'de',
  },
}

// @ts-ignore
export default new Vuetify(opts)
