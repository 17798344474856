import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class XIcon extends Vue {

  @Prop({ default: '' })
  public iconClass!: string

  @Prop({ default: 16 })
  public top!: number

  @Prop({ default: 16 })
  public size!: number
}
