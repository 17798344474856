import Period from '@/common/components/periods/period'
import moment, { Moment, unitOfTime } from 'moment'
import { Component, Mixins, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import Dates from '@/app/utils/dates'

@Component
export default class DateNav extends Vue {

  @Prop({ required: true })
  private value!: Period

  @Prop({ default: false })
  private periodIsMonthly!: boolean

  @Prop({ default: 'seconds' })
  private unit!: unitOfTime.Base

  @Prop({ default: 'iso' })
  private format!: 'date' | 'iso'

  @Prop({ type: Boolean, default: false})
  private dateSelectionDisabled!: boolean 

  private period = [this.value.start, this.value.end].sort()
  private focusDatePicker = false

  get title() {
    if (this.period) {
      // Sort to be sure that period[0] < period[1].
      this.period = this.period.sort()
      const start = moment(this.period[0])
      const end = moment(this.period[1])
      return Dates.periodText(start, end, this.unit)
    }
    return ''
  }

  public prev() {
    const start = moment(this.period[0])
    const end = moment(this.period[1])
    const step = this.getStep(start, end)

    if (this.periodIsMonthly) {
      const newStart = moment(start).add(-1, 'months')
      const newEnd = moment(newStart).endOf('month')
      this.period[0] = this.getFormat(newStart)
      this.period[1] = this.getFormat(newEnd)
    } else {
      this.period[0] = this.getFormat(start.subtract(step, this.unit))
      this.period[1] = this.getFormat(end.subtract(step, this.unit))
    }

    const p = this.getPeriod()
    this.$emit('input', p)
    this.$emit('input:prev', p)
  }

  public next() {
    const start = moment(this.period[0])
    const end = moment(this.period[1])
    const step = this.getStep(start, end)

    if (this.periodIsMonthly) {
      const newStart = moment(start).add(1, 'months')
      const newEnd = moment(newStart).endOf('month')
      this.period[0] = this.getFormat(newStart)
      this.period[1] = this.getFormat(newEnd)
    } else {
      this.period[0] = this.getFormat(start.add(step, this.unit))
      this.period[1] = this.getFormat(end.add(step, this.unit))
    }

    const p = this.getPeriod()
    this.$emit('input', p)
    this.$emit('input:next', p)
  }

  @Watch('value', { deep: true })
  private valueChanged(val: Period, old: Period) {
    this.period = [val.start, val.end].sort()
  }

  private onChange() {
    const p = this.getPeriod()
    this.$emit('input', p)
    this.$emit('input:pick', p)
  }

  private getPeriod() {
    const p = new Period()
    // Copy settings from the original period object.
    p.timeIncluded = this.value.timeIncluded
    // Sort to be sure that period[0] < period[1].
    this.period = this.period.sort()
    p.start = this.period[0]
    p.end = this.period[1]
    return p
  }

  private getStep(start: moment.Moment, end: moment.Moment) {
    const absDiff = Math.abs(end.diff(start, this.unit))
    return Math.max(absDiff, 1)
  }

  private getFormat(m: moment.Moment) {
    switch (this.format) {
      case 'date':
        return m.format('YYYY-MM-DD')
      case 'iso':
        return m.toISOString(true)
    }
    throw new Error(`[date-nav]: Invalid format [${this.format}].`)
  }
}
