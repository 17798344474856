import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class SideMenu extends Vue {

  @Prop()
  public value!: boolean

  public show = true

  private pValue: boolean = this.value

  @Watch('value')
  public valueChanged(newVal: boolean, oldVal: boolean) {
    if (newVal !== oldVal) {
      this.pValue = newVal
    }
  }

  @Watch('pValue')
  public pValueChanged(newVal: boolean, oldVal: boolean) {
    if (newVal !== oldVal) {
      this.$emit('input', newVal)
    }
  }

  public updated() {
    this.show = this.$route.name !== 'login'
  }
}
