import Guid from '@/app/utils/guid'

export default class CustomerCategory {

  public id: string = Guid.empty()
  public title: string = ''

  public get isNew() {
    return this.id == Guid.empty()
  }
}
