import VeeValidate from 'vee-validate'
import { Validator } from 'vee-validate'
import Vue from 'vue'

const config = {
    locale: 'de',
}

Validator.localize('de', {
    messages: {
        required: (field) => `Feld ${field} bitte ausfüllen.`,
        confirmed: (field1, field2) => `${field1} und ${field2} stimmen nicht überein.`,
        email: (field) => `Der Wert von ${field} ist ungültig.`,
        regex: (field) => `Der Wert von ${field} ist ungültig.`,
        min: (field) => `Der Wert von ${field} ist ungültig.`,
        max: (field) => `Der Wert von ${field} ist ungültig.`,
        alpha: (field) => `Der Wert von ${field} kann nur Text sein.`,
        numeric: (field) => `${field} muss eine Zahl sein.`,
        decimal: (field) => `${field} muss eine Zahl sein.`,
        min_value: (field, val) => `${field} muss großer gleich ${val} sein.`,
    },
    attributes: {
        firstName: 'Vorname',
        lastName: 'Nachname',
        email: 'E-Mail',
        role: 'Rolle',
        name: 'Name',
        password: 'Passwort',
        passwordConfirmation: 'Passwortbestätigung',
        postalCode: 'PLZ',
        phone: 'Telefonnummer',
        company: 'Firma',
        vat: 'UID-Nummer',
        addressLine: 'Adresse',
        countryId: 'Land',
        city: 'Ort',
        ridesSelected: 'Aufträge',
        paymentMethod: 'Zahlungsart',
        numberOfPassengers: 'Personenanzahl',
        duration: 'Dauer',
        distance: 'Kilometer',
        cost: 'Betrag',
        interval: 'Intervall',
    },
})

Vue.use(VeeValidate, config)
