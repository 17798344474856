import axios from '@/app/axios'
import Responses from '@/app/utils/responses'
import CrudService from '@/common/services/crud-service'
import Customer from '@/customers/entities/customer'
import CustomerCategory from '@/customers/entities/customer-category'
import PaginationRequest from '@/common/components/pagination/pagination-request'
import PaginationResponse from '@/common/components/pagination/pagination-response'
import buildQuery from 'odata-query'

class CustomersService extends CrudService<Customer> {

  // customers

  public async searchAsync(text: string) {
    const url = `${this.resource}/search`
    const res = await axios.get<Customer[]>(url, {
      params: {
        text,
      },
    })
    return Responses.list(res)
  }

  public async filterAllAsync(text: string, paging: PaginationRequest) {
    const res = await axios.post<PaginationResponse<Customer>>(this.resource, {
      text: text,
      paging: paging,
    })
    return Responses.pagedList(res)
  }

  // public async odata(text: string, paging: PaginationRequest) {
  //   const filter = { firstName: { contains: (text ? text : '') } }
  //   const top = paging.itemsPerPage
  //   const skip = (paging.page - 1) * paging.itemsPerPage
  //   const count = true
  //   const res = await axios.post<PaginationResponse<Customer>>(`${this.resource}/odata${buildQuery({ count, filter, top, skip })}`)
  //   return Responses.pagedList(res)
  // }

  // categories

  public async findAllCategoriesAsync() {
    const res = await axios.get<CustomerCategory[]>(`${this.resource}/categories`)
    return Responses.list(res)
  }

  public async saveCategoryAsync(entity: CustomerCategory) {
    const res = await axios.post<CustomerCategory>(`${this.resource}/createCategory`, entity)
    return Responses.obj(res)
  }

  public async updateCategoryAsync(entity: CustomerCategory) {
    const res = await axios.put<CustomerCategory>(`${this.resource}/updateCategory`, entity)
    return Responses.obj(res)
  }

  public async removeCategoryAsync(id: string) {
    await axios.post(`${this.resource}/${id}/deleteCategory`)
  }
}

export default new CustomersService('customers')
